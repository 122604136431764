import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/atoms/seo";
import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import SectionFold from "../components/organisms/section-fold";
import SectionImageBlock from "../components/organisms/section-image-block";
import SectionForm from "../components/organisms/section-form";

const AboutUsPage = (props) => {
  const generalData = props.data?.generalData;
  const dictionary = props.data?.dictionary?.edges[0]?.node;
  const pageData = props.data?.pageData?.edges[0]?.node;
  const pagesData = props.data?.pagesData?.edges;
  const contactPageData = props.data?.contactPageData?.edges[0]?.node;
  const coreValues = props.data?.coreValues?.edges;

  return (
    <Layout>
      <Seo title={pageData.pageTitle} />
      <Header generalData={generalData} pagesData={pagesData} />
      <main>
        <SectionFold pageData={pageData} bgColor="bg-light" showScrollTo={true} />
        <SectionImageBlock values={coreValues} priorityIntro={pageData.priorityIntro} />
        <SectionForm generalData={generalData} dictionary={dictionary} contactPageData={contactPageData} contactIntro={pageData.contactIntro} renderMapAside={true} />
      </main>
      <Footer generalData={generalData} pagesData={pagesData} />
    </Layout>
  );
};

export const query = graphql`
  query {
    generalData: contentfulGeneralSettings {
      footerEmail
      footerPhoneNumber
      logo {
        fluid(maxWidth: 284, maxHeight: 160) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      contactLocation {
        lat
        lon
      }
      contactMarker {
        raw
      }
    }
    dictionary: allContentfulDictionary {
      edges {
        node {
          contactDisclaimer {
            raw
          }
          contactEmailLabel
          contactErrorMessage {
            raw
          }
          contactMessageLabel
          contactNameLabel
          contactSubmit
          contactSuccessMessage {
            raw
          }
        }
      }
    }
    pagesData: allContentfulPage {
      edges {
        node {
          slug
          pageName
          id
          hideInNavigation
          hideInFooter
          navigationOrder
        }
      }
    }
    pageData: allContentfulPage(filter: { slug: { eq: "/about-us" } }) {
      edges {
        node {
          id
          slug
          description {
            raw
          }
          callToActions {
            id
            buttonText
            buttonVariant
            target {
              ... on ContentfulCareerOpportunities {
                slug
              }
              ... on ContentfulPage {
                slug
              }
            }
            textVariant
          }
          pageTitle
          pageHeaderImage {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          priorityIntro {
            freeText {
              raw
            }
            callToActions {
              buttonText
              buttonVariant
              id
              target {
                ... on ContentfulCareerOpportunities {
                  slug
                }
                ... on ContentfulPage {
                  slug
                }
              }
            }
            illustration {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
          contactIntro {
            freeText {
              raw
            }
            callToActions {
              buttonText
              buttonVariant
              id
              target {
                ... on ContentfulCareerOpportunities {
                  slug
                }
                ... on ContentfulPage {
                  slug
                }
              }
            }
            illustration {
              fluid(maxWidth: 1920, quality: 80) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    }
    contactPageData: allContentfulPage(filter: { slug: { eq: "/contact" } }) {
      edges {
        node {
          pageTitle
        }
      }
    }
    coreValues: allContentfulCoreValues {
      edges {
        node {
          description {
            raw
          }
          id
          image {
            fluid(maxWidth: 750, maxHeight: 750, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          priority
          title
        }
      }
    }
  }
`;

export default AboutUsPage;
